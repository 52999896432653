import React from 'react'
import PaymentCardBridge from './PaymentCardBridge'
import { ENV } from './services/Configs/ConfigsService'

const getEnv = (): ENV => {
  switch (import.meta.env.VITE_ENV) {
    case 'PROD':
      return ENV.PROD
    case 'DEV':
      return ENV.DEV
    case 'LOCAL':
      return ENV.LOCAL
    default:
      return ENV.PROD
  }
}
const App = (): JSX.Element => {
  const env = getEnv()
  return <PaymentCardBridge env={env} />
}

export default App
