import React from 'react'

import { ThemeProvider } from 'styled-components'
import GlobalStyles from './GlobalStyles'

export const themeConfig = {
  colors: {
    primary: {
      background: '#1A1A1A',
      text: '#FFF',
      body: '#1A1A1A',
      link: '#1A1A1A'
    },
    primaryLight: {
      background: '#717171',
      text: '#FFF',
      body: '#1A1A1A',
      link: '#1A1A1A'
    },
    white: {
      t700: '#FFFFFF',
      t500: '#F5F5F5',
      t400: '#F2F2F2'
    },
    gray: {
      t800: '#141414',
      t700: '#1A1A1A',
      t600: '#3e3e3e',
      t500: '#717171',
      t400: '#A6A6A6',
      t350: '#B0B2B7',
      t300: '#DCDCDC',
      t200: '#F9F9F9'
    },
    success: {
      t400: '#377E62',
      t500: '#317057'
    },
    error: {
      t400: '#FCE5E2',
      t500: '#BB2111',
      t600: '#BB111E'
    },
    info: {
      t400: '#3569C4'
    }
  },
  fontWeight: {
    thin: 100,
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900
  },
  radius: {
    sm: '2px',
    base: '4px',
    medium: '8px',
    rounded: '50%'
  },
  fonts: ['Sul Sans', 'Arial'],
  fontsSizes: {
    xs: {
      size: '0.75rem', // 12px
      lineHeight: '1rem' // 16px
    },
    sm: {
      size: '0.875rem', // 14px
      lineHeight: '1.25rem' // 20px
    },
    base: {
      size: '1rem', // 16px
      lineHeight: '1.5rem' // 24px
    },
    lg: {
      size: '1.125rem', // 18px
      lineHeight: '1.75rem' // 28px
    },
    xl: {
      size: '1.25rem', // 20px
      lineHeight: '1.75rem' // 28px
    },
    xxl: {
      size: '1.5rem', // 24px
      lineHeight: '2rem' // 32px
    },
    xxxl: {
      size: '1.875rem', // 30px
      lineHeight: '2.25rem' // 36px
    }
  }
}

export interface ThemeProps {
  children: React.ReactNode
  customStyles?: object
}

export enum ThemeVariant {
  Primary = 'primary',
  PrimaryLight = 'primaryLight'
}

export default function Theme ({ children, customStyles }: Readonly<ThemeProps>): React.ReactElement {
  const config = {...themeConfig, ...customStyles}
  return <ThemeProvider theme={ config }>
    <GlobalStyles />
    {children}
  </ThemeProvider>
}
