import * as applicationProperties from '../../configs/application.properties.json'

export enum ENV {
  DEV = 'DEV',
  PROD = 'PROD',
  LOCAL = 'LOCAL'
}
export default class ConfigService {
  private readonly currentEnv: string
  private readonly currentConfigs: Record<string, string>
  constructor (env: ENV) {
    this.currentEnv = env
    switch (env) {
      case ENV.PROD:
        this.currentConfigs = applicationProperties.PROD
        break;
      case ENV.DEV:
        this.currentConfigs = applicationProperties.DEV
        break;
      case ENV.LOCAL:
        this.currentConfigs = applicationProperties.LOCAL
        break;
      default:
        this.currentConfigs = applicationProperties.DEV
    }
  }

  get = <T,>(configName: string): T => {
    if (!(configName in this.currentConfigs)) throw new Error(`Invalid config (${configName}) in ${this.currentEnv} env`)
    const config = this.currentConfigs[configName]
    return config as T
  }

  has = (configName: string): boolean => {
    return (configName in this.currentConfigs)
  }
}
