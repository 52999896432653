import { type RatierVaultErrorResponse } from '../repositories/RatierVaultRepository'

export class EventEmitterService {
  private readonly parentWindow: Window
  private target: string | null
  constructor (window: Window) {
    this.parentWindow = window
    this.target = null
  }

  private sendEvent (target: string | null, event: OnSuccessEvent | OnErrorEvent | OnTokenizeErrorEvent | OnHandShakeEvent | OnBeforeTokenize) {
    console.debug(`Sending ${event.type} event`)

    if(target === null){
      throw Error("It's not possible to send post message events because target was not set!")
    }else{
      this.parentWindow.postMessage(event, target)
    }
  }

  setTarget(target: string){
    this.target = target
  }

  sendSuccessEvent (data: SuccessResponse) {
    this.sendEvent(this.target, { type: 'ON_SUCCESS', data })
  }

  sendBeforeTokenizeEvent (data: { ok: boolean }) {
    this.sendEvent(this.target, { type: 'ON_BEFORE_TOKENIZE', data })
  }

  sendTokenizeErrorEvent (dataError: string) {
    this.sendEvent(this.target, { type: 'ON_TOKENIZE_ERROR', data: { 'data-error': dataError } })
  }

  sendErrorEvent (error: Error) {
    console.error(error)
    this.sendEvent(this.target, { type: 'ON_ERROR', data: error })
  }

  sendHandShakeEvent (target: string, ok: boolean) {
    this.sendEvent(target, { type: 'ON_HANDSHAKE', data: { ok } })
  }
}

export interface OnHandShakeEvent {
  type: string
  data: {
    ok: boolean
  }
}
export interface OnBeforeTokenize {
  type: string
  data: {
    ok: boolean
  }
}
export interface OnSuccessEvent {
  type: string
  data: SuccessResponse
}

export interface OnTokenizeErrorEvent {
  type: string
  data: RatierVaultErrorResponse
}

export interface OnErrorEvent {
  type: string
  data: Error
}

export interface SuccessResponse {
  card: string
  last4digits: string
  paymentMethodId: string
  cvv?: string | null
}
