import { type IRepository } from './Repository'

export interface TokenizeRequest {
  tokenizationUrl: string
  acquirers: any
  buyer: Record<string, string>
  card: string
  account_id: string
  isTest: boolean
}
export interface IRatierVaultRepository {
  tokenize: (request: TokenizeRequest) => Promise<RatierVaultResponse>
}

export interface RatierVaultResponse {
  card: string
}

export interface RatierVaultErrorResponse {
  'data-error': string
}

export class RatierVaultRepository implements IRatierVaultRepository {
  private readonly repository: IRepository
  constructor (repository: IRepository) {
    this.repository = repository
  }

  async tokenize (request: TokenizeRequest): Promise<RatierVaultResponse> {
    const ratierPayload = {
      acquirers: request.acquirers,
      buyer: request.buyer,
      card: request.card
    }
    
    const ratierRequestConfigHeaders = {
      account_id: request.account_id,
      country: 'BR',
      platform: 'Desktop',
      session_id: request.account_id,
      app_version: '', // TODO implements based on package json version
      'X-Test-Mode': request.isTest ? 'true' : 'false',
      'X-Test-Scope': request.isTest ? 'can_test' : ''
    }

    const url = (request.tokenizationUrl == "" || request.tokenizationUrl == null)? '/customers/me/card-tokens' : request.tokenizationUrl
  
    return await this.repository.Call<RatierVaultResponse>(url, {
      method: 'POST',
      body: JSON.stringify(ratierPayload),
      headers: ratierRequestConfigHeaders
    })
  }
}
