import { Aboyeur, Metadata } from '@ifood/aboyeur';
import { SentryBrowserPlugin, SentryBrowserMetadata } from '@ifood/aboyeur-plugin-sentry-browser';
import { BrowserTracing } from '@sentry/react';
import pkgJson from '../../package.json'
import { GoogleAnalyticsPlugin, GoogleAnalyticsMetadata } from '@ifood/aboyeur-plugin-ga';

// Setting up local plugins
const PLUGINS = {
  [SentryBrowserPlugin.displayName]: SentryBrowserPlugin,
  [GoogleAnalyticsPlugin.displayName]: GoogleAnalyticsPlugin,
};

type AboyeurMetadata = SentryBrowserMetadata & GoogleAnalyticsMetadata;

// Every plugin will have access to this data with `this.metadata`

const ABOYEUR_METADATA: AboyeurMetadata = {
    sentryBrowser: {
        release: import.meta.env.VITE_RELEASE_VERSION || `${pkgJson.name}@${pkgJson.version}` ||  "payment-card-bridge@latest",
        environment: import.meta.env.VITE_ENV,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
        debug: import.meta.env.VITE_ENV !== 'PROD',
    },
    googleAnalytics: {
      trackingIds: [import.meta.env.VITE_GA_TRACKING_ID],
    }
  };



export function initializeAboyeur({ isTest = false }): void {
    console.debug('Initializing aboyeur!')
    const dryRun = import.meta.env.VITE_ENV === 'DEV';
  
    const configMetadata = ABOYEUR_METADATA;
  
    Aboyeur.initialize(configMetadata, PLUGINS, { dryRun, isTest });
  }
  

// Initialization
type CustomMetadata = {
    leadId: string;
  };
  
  function mountAboyeurMetadata(metadata: AboyeurMetadata, { leadId }: CustomMetadata): AboyeurMetadata {
    return {
      ...metadata,
      sentryBrowser: {
        ...metadata.sentryBrowser,
        user: {
          id: leadId,
        },
      },
    };
  }
  
  const ABOYEUR_METADATA_MANDATORY_KEYS: (keyof AboyeurMetadata)[] = ['sentryBrowser'];
  
  function isAboyeurMetadata(metadata: Metadata): metadata is AboyeurMetadata {
    if (!metadata || typeof metadata !== 'object') {
      return false;
    }
  
    return ABOYEUR_METADATA_MANDATORY_KEYS.every((key) => key in metadata);
  }
  
  export function updateAboyeurMetadata(customMetadata: CustomMetadata): void {
    Aboyeur.updateMetadata((metadata) => {
      if (!isAboyeurMetadata(metadata)) {
        return metadata;
      }
  
      return mountAboyeurMetadata(metadata, customMetadata);
    });
  }