// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// doc about hybrid cryptography
// https://rwondemand.atlassian.net/wiki/spaces/CPI/pages/1280934006/Ratier+Vault+-+Encrypt+decrypt+code+example

import RSAKey from './rsaKey'

const rsa = new RSAKey()

const getRfc3339Date = () => {
  const now = new Date()

  function pad (number) {
    return number < 10 ? `0${number}` : number
  }

  function timezoneOffset (offset) {
    if (offset === 0) {
      return 'Z'
    }
    const sign = offset > 0 ? '-' : '+'
    const positiveOffset = Math.abs(offset)
    const result = `${sign}${pad(Math.floor(positiveOffset / 60))}:${pad(positiveOffset % 60)}`

    return result
  }

  return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}T${pad(now.getHours())}:${pad(
    now.getMinutes()
  )}:${pad(now.getSeconds())}${timezoneOffset(now.getTimezoneOffset())}`
}

const appendBuffer = (buffer1, buffer2) => {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength)
  tmp.set(new Uint8Array(buffer1), 0)
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength)
  return tmp.buffer
}

const base64ToArrayBuffer = base64 => {
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}

const hexToBase64 = str => {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ')
    )
  )
}

function base64ToHex (str) {
  const raw = atob(str)
  let result = ''
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16)
    result += hex.length === 2 ? hex : `0${hex}`
  }
  return result.toUpperCase()
}

export async function hibrydEncrypt (data, secret) {
  const pem = String.fromCharCode.apply(null, new Uint8Array(base64ToArrayBuffer(secret)))
  const pemHeader = '-----BEGIN PUBLIC KEY-----'
  const pemFooter = '-----END PUBLIC KEY-----'

  // remover header and footer from pem key
  const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length)

  // import public key
  const publicKey = await window.crypto.subtle.importKey(
    'spki',
    base64ToArrayBuffer(pemContents),
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256'
    },
    true,
    ['encrypt']
  )

  // export public key in jwk format
  const exportedKey = await window.crypto.subtle.exportKey('jwk', publicKey)

  // get modulus from key
  const hexKey = base64ToHex(exportedKey.n.replace(/-/g, '+').replace(/_/g, '/'))
  // get exponent from key
  const hexExponent = base64ToHex(exportedKey.e)

  rsa.setPublic(hexKey, hexExponent)

  // generate a random iv
  const iv = window.crypto.getRandomValues(new Int8Array(16))

  // generate a symmetric key
  const key = await window.crypto.subtle.generateKey(
    {
      name: 'AES-CBC',
      length: 256
    },
    true,
    ['encrypt', 'decrypt']
  )

  const nowDate = getRfc3339Date()

  // encrypt text with date now e.g: "{'holder-name': 'holder-name',...}|2020-08-03T15:10:33-03:00"
  const firstCyprt = await window.crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv
    },
    key,
    new TextEncoder().encode(`${JSON.stringify(data)}|${nowDate}`)
  )

  // concat iv with encrypted text above
  const ivWithKey = appendBuffer(iv, new Int8Array(firstCyprt))
  const encryptedText = window.btoa(String.fromCharCode.apply(null, new Uint8Array(ivWithKey)))

  // export symmetric key
  const exported = await window.crypto.subtle.exportKey('raw', key)

  // convert key to hexadecimal
  const exportedHex = Array.from(new Uint8Array(exported))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('')

  // encrypt symmetric key using pem (public key from parameters)
  const encryptedKey = rsa.encrypt_b64(new Int8Array(base64ToArrayBuffer(hexToBase64(exportedHex))))

  // concat encryptedKey with pipe and encrypted text
  return `${encryptedKey}|${encryptedText}`
}
