import { Component, type ErrorInfo, type ReactNode } from 'react'
import { type EventEmitterService } from './services/EventEmitterService'

interface Props {
  children?: ReactNode
  repository: EventEmitterService
}

interface State {
  hasError: boolean
  error: Error | undefined
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined
  }

  public repository: EventEmitterService

  constructor (props: Props) {
    super(props)
    this.repository = props.repository
  }

  public static getDerivedStateFromError (error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  public componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render () {
    if (this.state.hasError && this.state.error !== undefined) {
      this.repository.sendErrorEvent(this.state.error)
    }

    return this.props.children
  }
}

export default ErrorBoundary
