import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import { initializeAboyeur } from './services/Monitoring';

function initializeMonitoring() {

  if (import.meta.env.VITE_ENV === "PROD") initializeAboyeur({})
}

initializeMonitoring()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
