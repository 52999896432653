export const handleMask = (mask: string | undefined): string => {
  switch (mask) {
    case 'postal_code':
      return '00000-000'
    case 'document':
    case 'company':
      return '000.000.000-00'
    case 'phone':
      return '(00) 00000-0000'
    case 'credit_card':
      return '0000 0000 0000 0000'
    case 'date':
      return '00/00'
    case 'code':
      return '000'
    default:
      return ''
  }
}

export const handleLengthMask = (mask: string | undefined): number => {
  switch (mask) {
    case 'postal_code':
      return 8
    case 'document':
      return 11
    case 'company':
      return 15
    case 'phone':
      return 11
    case 'credit_card':
      return 16
    case 'date':
      return 4
    case 'code':
      return 4
    default:
      return 0
  }
}

export const handleFormatValue = (mask: string | undefined, value: string | undefined): string => {
  const checkValueIsNotUndefined = value !== undefined ? value : ''
  switch (mask) {
    case 'postal_code':
      return checkValueIsNotUndefined ? formatCEP(removeMasks(checkValueIsNotUndefined)) : ''
    case 'document':
      return checkValueIsNotUndefined ? formatDocument(removeMasks(checkValueIsNotUndefined)) : ''
    case 'company':
      return checkValueIsNotUndefined && checkValueIsNotUndefined.length > 11 ? formatCompany(removeMasks(checkValueIsNotUndefined)) : formatDocument(removeMasks(checkValueIsNotUndefined))
    case 'phone':
      return checkValueIsNotUndefined ? formatPhone(removeMasks(checkValueIsNotUndefined)) : ''
    case 'credit_card':
      return checkValueIsNotUndefined ? formatCreditCard(removeMasks(checkValueIsNotUndefined)) : ''
    case 'date':
      return checkValueIsNotUndefined ? formatCardDate(checkValueIsNotUndefined) : ''
    case 'code':
      return checkValueIsNotUndefined ? checkValueIsNotUndefined : ''
    default:
      return ''
  }
}

export const formatCEP = (postalCode: string): string => {
  return postalCode.replace(/(\d{5})(\d{3})/, '$1-$2')
}

export const formatDocument = (document: string): string => {
  if (document.length === 11) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export const formatCompany = (company: string): string => {
  if (company.length > 11) {
    return company.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  return company.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export const formatPhone = (phone: string): string => {
  if (phone.length > 11) {
    return phone.replace('55', '').replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
  }
  return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
}

export const formatCreditCard = (creditCard: string): string => {
  return creditCard.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4')
}

export const formatCardDate = (date: string): string => {
  return date.replace(/(\d{2})(\d{2})/, '$1/$2')
}

export const removeMasks = (value: string): string => {
  return value != null ? value.replace(/\D/g, '') : ''
}

export const documentHideCharacters = (value: string): string => {
  return `${value.slice(0, 3)}${'.***.'}${'***'}${value.slice(-2)}`
}

export const phoneHideCharacters = (value: string): string => {
  return `${'('}${value.slice(2, 4)}${') '}${'* '}${value.slice(6, 8)}${'**-**'}${value.slice(-2)}`
}
