import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useWindowDimensions } from "../../../utils/windowSize";
import {
    Card,
    Flex,
    Input,
    InputMask,
    CreditCardInput,
    Paragraph,
    InputLabel,
    Checkbox,
    Span,
} from "@ifood/payin-react-components";
import { PaymentCard } from "../../../services/interfaces/PaymentCard";
import { PaymentMethod } from "../../../domain/PaymentMethod";
import { cnpj, cpf } from "cpf-cnpj-validator";
import BoxSecuredByIfoodPago from "../BoxSecuredByIfoodPago/BoxSecuredByIfoodPago";

const CustomStyle = styled.div`
    .payin_label {
        ${props => props.theme.input_label}
    }
    .payin_input__wrapper {
        svg {
            ${props => props.theme.input_icon}
        }
        .payin_input {
            ${props => props.theme.input}
            & .error {
                ${props => props.theme.input_error}
            }
        }
        .error-message {
            ${props => props.theme.input_error_message}
        }
        .count-chars, span {
            ${props => props.theme.input_count}
        } 
    }
`

export const CardStyled = styled(Card)`
    ${props => props.theme.form_box};
    position: relative;

    @media only screen and (min-width: 650px) and (max-width: 768px) {
        padding: 24px;
    }

    @media only screen and (max-width: 650px) {
        padding: 16px;
    }
`;

export const ParagraphStyled = styled(Paragraph)`
    margin: 0 0 12px;
`;

export const FlexStyled = styled(Flex)`
    margin: 32px 0 0;

    @media only screen and (max-width: 650px) {
        margin: 32px 0 8px;
        label {
            max-width: 140px;
        }
    }
`;

export const FlexPurchasesStyled = styled(Flex)`
    padding: 24px 0 0 0;

    @media only screen and (max-width: 650px) {
        padding: 19px 0 8px 0;
    }
`;

export const InputLabelStyled = styled(InputLabel)`
    display: block;
    margin-bottom: 8px;

    @media only screen and (max-width: 650px) {
        display: block;
    }
`;

export const InputLabelRelativeStyled = styled(InputLabelStyled)`
    position: relative;

    @media only screen and (min-width: 650px) and (max-width: 768px) {
        width: 275px;
    }
`;


export const InputStyled = styled(Input)`
    
    margin-top: 8px;
`;

export const InputIconStyled = styled(CreditCardInput)`
    margin-top: 8px;
    & svg {
        margin-top: 8px;
        width: 50px;
    }
`;

export const InputMaskStyled = styled(InputMask)`
    margin-top: 8px;
    @media only screen and (min-width: 650px) and (max-width: 768px) {
        width: 275px;
    }

    & svg {
        margin-top: 1px;
        width: 50px;

    }
`;

export const DividerStyled = styled.hr`
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid #EBEBEB;
`;

export const CheckboxStyled = styled(Checkbox)`
    margin: 0 7px 0 11px;
`;

export const SpanTextStyled = styled(Span)`
    bottom: 0;
    position: absolute;
    color: ${({ theme }) => theme.colors.gray.t500};
    font-size: ${({ theme }) => theme.fontsSizes.sm.size};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export const SpanStyled = styled(Span)`
    font-size: ${({ theme }) => theme.fontsSizes.sm.size};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
`;


interface FormCreditCardProps {
    availablePaymentMethods: PaymentMethod[];
}
export const FormCreditCard: React.FC<FormCreditCardProps> = (props) => {
    const { width } = useWindowDimensions();
    const WindowsResizeReference = 650;

    const {
        register,
        setValue,
        formState: { errors, touchedFields },
    } = useFormContext<PaymentCard>();

    const validPaymentMethod = (cardNumber: string) => {
        const cardNumberOnly = cardNumber.replace(/[^0-9]/g, "");
        const isValid = props.availablePaymentMethods.some((paymentMethod) => {
            if (paymentMethod.brand.regex === "") return false;
            const regex = new RegExp(paymentMethod.brand.regex, "i");
            return regex.test(cardNumberOnly);
        });
        return isValid;
    };

    const isValidExpiration = (value: string): boolean => {
        if (value.length !== 4) return false;
        const month = parseInt(value.substring(0, 2));
        const year = parseInt("20" + value.substring(2, 4));
        if (month < 1 || month > 12) return false;

        const today = new Date();
        const someDay = new Date();
        someDay.setFullYear(year, month - 1);
        return someDay >= today;
    };

    const documentIsValid = (document: string): boolean => {
        const numberOnly = document.replace(/[^0-9]/g, "");
        if (numberOnly.length > 11) return cnpj.isValid(numberOnly);

        return cpf.isValid(numberOnly);
    };

    const rules = {
        cardNumber: {
            required: true,
            minLength: 14,
            validate: { validPaymentMethod },
        },
        holderName: {
            required: true,
            pattern:
                /(\s?)^[A-Za-z'\-,.]+( [A-Za-z'\-,.]+)[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/,
        },
        expiration: {
            required: true,
            minLength: 4,
            validate: { isValidExpiration },
        },
        cvv: { required: true, minLength: 3 },
        document: {
            required: true,
            minLength: 11,
            validate: { documentIsValid },
        },
    };

    const { t } = useTranslation('common');
    const labelTitle = t("form_credit_card.title");
    const labelNumberCreditCard = t("form_credit_card.number_credit_card");
    const labelNameCreditCard = t("form_credit_card.name_credit_card");
    const labelDateMob = t("form_credit_card.date_mob");
    const labelDateDesk = t("form_credit_card.date_desk");
    const labelCodeMob = t("form_credit_card.code_mob");
    const labelCodeDesk = t("form_credit_card.code_desk");
    const labelDocument = t("form_credit_card.document");
    const labelDocumentDescription = t("form_credit_card.document_description");

    return (
        <CustomStyle>
        <CardStyled padding="24px">
            <ParagraphStyled type="info">* {labelTitle}</ParagraphStyled>

            <InputLabelStyled required title={labelNumberCreditCard}>
                <InputIconStyled
                    inputMode={"numeric"}
                    value=""
                    {...register("number", rules.cardNumber)}
                    ref={null}
                    onChange={(evt: any) =>
                        setValue("number", evt?.target.value, {
                            shouldValidate: true,
                            shouldTouch: true,
                        })
                    }
                    count
                    error={"number" in errors}
                    errorText="Número inválido"
                />
            </InputLabelStyled>

            <InputLabelStyled required title={labelNameCreditCard}>
                <InputStyled
                    placeholder="Nome e sobrenome"
                    {...register("holderName", rules.holderName)}
                    ref={null}
                    onChange={(evt: any) =>
                        setValue("holderName", evt?.target.value, {
                            shouldValidate: true,
                            shouldTouch: true,
                        })
                    }
                    success={"holderName" in touchedFields}
                    error={"holderName" in errors}
                    errorText="Nome inválido"
                />
            </InputLabelStyled>

            <FlexStyled justifyContent="space-between">
                <InputLabel
                    required
                    title={
                        width < WindowsResizeReference
                            ? labelDateMob
                            : labelDateDesk
                    }
                >
                    <InputMaskStyled
                        inputMode={"numeric"}
                        width="275px"
                        mask="date"
                        {...register("expiration", rules.expiration)}
                        ref={null}
                        onChange={(evt: any) =>
                            setValue("expiration", evt?.target.value, {
                                shouldValidate: true,
                                shouldTouch: true,
                            })
                        }
                        success={"expiration" in touchedFields}
                        count
                        error={"expiration" in errors}
                        errorText="Data inválida"
                    />
                </InputLabel>
                <InputLabel
                    required
                    title={
                        width < WindowsResizeReference
                            ? labelCodeMob
                            : labelCodeDesk
                    }
                >
                    <InputMaskStyled
                        inputMode={"numeric"}
                        width="275px"
                        mask="code"
                        {...register("cvv", rules.cvv)}
                        ref={null}
                        onChange={(evt: any) =>
                            setValue("cvv", evt?.target.value, {
                                shouldValidate: true,
                                shouldTouch: true,
                            })
                        }
                        count
                        success={"cvv" in touchedFields}
                        error={"cvv" in errors}
                        errorText="Código inválido"
                    />
                </InputLabel>
            </FlexStyled>

            <InputLabelRelativeStyled required title={labelDocument}>
                <InputMaskStyled
                    inputMode={"numeric"}
                    width="275px"
                    mask="document"
                    {...register("document", rules.document)}
                    ref={null}
                    onChange={(evt: any) =>
                        setValue("document", evt?.target.value, {
                            shouldValidate: true,
                            shouldTouch: true,
                        })
                    }
                    success={"document" in touchedFields}
                    count
                    error={"document" in errors}
                    errorText="CPF inválido"
                />
                {errors.document === undefined ? (
                    <SpanTextStyled>{labelDocumentDescription}</SpanTextStyled>
                ) : null}
            </InputLabelRelativeStyled>
            <DividerStyled/>
            <BoxSecuredByIfoodPago/>
        </CardStyled>
        </CustomStyle>
    );
};

export default FormCreditCard;
