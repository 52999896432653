import { createGlobalStyle } from 'styled-components'
import SulSansRegular from './fonts/Sul_Sans/Sul_Sans_Regular.otf'
import SulSansBold from './fonts/Sul_Sans/Sul_Sans_Bold.otf'
import SulSansLight from './fonts/Sul_Sans/Sul_Sans_Light.otf'
import 'react-toastify/dist/ReactToastify.min.css'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Sul Sans';
    src: url(${SulSansRegular});
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Sul Sans';
    src: url(${SulSansBold}) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sul Sans';
    src: url(${SulSansLight}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  body {
   font-family: 'Sul Sans', sans-serif;
    margin: 0px;
  }
`

export default GlobalStyles
