import useSWRMutation from 'swr/mutation'
import {
  type IRatierVaultRepository,
  type TokenizeRequest
} from '../repositories/RatierVaultRepository'
import { type PaymentCard } from '../domain/PaymentCard'
import { type PaymentMethod } from '../domain/PaymentMethod'

export interface TokenizeActionRequest {
  accountId: string
  deviceId: string
  devicePaymentSecret: string
  card: PaymentCard
  paymentMethod: PaymentMethod
  isTest: boolean
  externalVerificationId: string,
  additionalData?: Record<string, string>
}

export type EncryptCardFn = (data: any, publicKey: string) => Promise<string>

const removeMasks = (value: string): string => {
  return value != null ? value.replace(/\D/g, '') : ''
}

export const useTokenizeCard = (repository: IRatierVaultRepository, encryptFn: EncryptCardFn) => {
  const mutator = async (arg: string, options: { arg: TokenizeRequest }) => {
    return await repository.tokenize(options.arg)
  }

  const options = {}

  const { data, trigger, isMutating } = useSWRMutation('/tokenize-card', mutator, options)

  return {
    tokenizedCard: data,
    isLoading: isMutating,
    doTokenize: async (request: TokenizeActionRequest) => {

      if (request.paymentMethod.token_configuration.providers.length === 0) throw new Error(`No provider found to Payment Method ${request.paymentMethod.brand.name}`)
      const provider = request.paymentMethod.token_configuration.providers[0]
      const tokenizationUrl = provider.url
      
      const toEncryptPayload = {
        'holder-name': request.card.holderName,
        'holder-document': removeMasks(request.card.document),
        'account-number': removeMasks(request.card.number),
        'security-code': removeMasks(request.card.cvv),
        'expiry-month': parseInt(removeMasks(request.card.expiration).substring(0, 2), 10),
        'expiry-year': parseInt(`20${removeMasks(request.card.expiration).substring(2, 4)}`, 10),
        'zero-dollar-validation': {
          credit: provider.zero_dollar,
          debit: false
        },
        'is-voucher': (request.paymentMethod.method.name === 'MEAL_VOUCHER'),
        'additional-data': {
          ...request.additionalData,
          alias: 'card-' + request.accountId,
          'external-account-validation-id': request.externalVerificationId,
          'brand-id': request.paymentMethod.brand.id,
          'device-payment-secret': request.devicePaymentSecret,
          'device-id': request.deviceId,
          'company-id': request.paymentMethod.company?.id ?? '',
          tenant: 'IFO', // TODO Necessário mudar quando tivermos integração com outros paises
          'card-type': request.paymentMethod.method.name,
          ip: '0.0.0.0'
        }
      }

      const encriptedData = await encryptFn(toEncryptPayload, provider.public_key)

      const apiRequest: TokenizeRequest = {
        tokenizationUrl: tokenizationUrl,
        acquirers: provider.acquirers,
        buyer: {
          'first-name': request.card.holderName,
          'last-name': '',
          email: '',
          document: request.card.document
        },
        account_id: request.accountId,
        card: encriptedData,
        isTest: request.isTest
      }
      return await trigger(apiRequest)
    }
  }
}
