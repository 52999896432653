import { last } from 'ramda'

export const COUNTRIES = {
  BR: 'BR',
  US: 'US'
}

export const LOCALES = {
  [COUNTRIES.BR]: 'pt-BR',
  [COUNTRIES.US]: 'es-US'
}

export const getDomainCountry = (hostname = window.location.hostname) =>
  hostname.includes('.') ? last(hostname.split('.')) : COUNTRIES.BR

export const getLocaleByDomainCountry = (domainCountry = getDomainCountry()) => {
  if (domainCountry !== undefined) {
    return LOCALES[domainCountry.toUpperCase()]
  }
}
