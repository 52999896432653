import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { getLocaleByDomainCountry } from '../utils/domain_country'
import { LOCALES } from '../constants'

import * as translation_BR from '../locales/pt-BR/common.json'
import * as translation_PO from '../locales/pt-BR/pending_order.json'
import * as translation_ANOTAI from '../locales/pt-BR/anotai.json'
import * as translation_US from '../locales/es-US/translation.json'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({

    resources: {
      pt: { 
        common: translation_BR,
        pending_order: translation_PO,
        anotai: translation_ANOTAI,
      },
      es: { translation: translation_US }
    },

    fallbackNS: 'common',
    lng: getLocaleByDomainCountry(),
    fallbackLng: LOCALES.BR,

    debug: true,

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
