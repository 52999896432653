import React from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import {
    Button,
    Flex,
    Heading,
    Paragraph,
} from "@ifood/payin-react-components";
import { FormCreditCard } from "../../molecules/FormCreditCard/FormCreditCard";
import styled from "styled-components";
import { PaymentCard } from "../../../services/interfaces/PaymentCard";
import { PaymentMethod } from "../../../domain/PaymentMethod";
import { removeMasks } from "../../../utils/formats";


const FormCreditCardValidationStyled = styled.div`
    ${props => props.theme.fontFamily ? `font-family: ${props.theme.fontFamily};` : ''}
`;

const ButtonStyled = styled(Button)`
    ${props => props.theme.submit_button};
    &.isvalid {
        ${props => props.theme.submit_button_valid};
    }
`;

export const HeadingStyled = styled(Heading)`
    margin: 0;
    margin-top: 32px;
    ${props => props.theme.form_title};
`;

export const ParagraphStyled = styled(Paragraph)`
    margin: 4px 0 24px;
    ${props => props.theme.form_description};
`;

export const FlexStyled = styled(Flex)`
    margin: 32px 0 0 0;
`;

export interface FormCreditCardValidationProps {
    availablePaymentMethods: PaymentMethod[];
    onSuccess: (data: PaymentCard) => void;
}

export const FormCreditCardValidation: React.FC<
    FormCreditCardValidationProps
> = (props): JSX.Element => {
    const defaultValues = {
        card_number: "",
        name: "",
        date: "",
        code: "",
        document: "",
        future_purchases: false,
    };
    const methods = useForm<PaymentCard>({ defaultValues });

    const onSubmit = (data: PaymentCard) => {
        data.payment_method_id = getPaymentMethodIdByCardNumber(data.number);
        props.onSuccess(data);
    };

    const getPaymentMethodIdByCardNumber = (cardNumber: string): string => {
        const filteredPaymentMethods = props.availablePaymentMethods.filter(
            (paymentMethod) => {
                if (paymentMethod.brand.regex === "") return false;
                const regex = new RegExp(paymentMethod.brand.regex, "i");
                return regex.test(removeMasks(cardNumber));
            }
        );
        if (filteredPaymentMethods.length === 0)
            throw new Error("Invalid payment method");
        return filteredPaymentMethods[0].id;
    };

    const { t } = useTranslation('common');
    const labelTitle = t("titles.card_data");
    const labelDescription = t("descriptions.card_data");
    const labelContinue = t("buttons.continue");

    return (
        <FormCreditCardValidationStyled>
            <HeadingStyled size="h4">{labelTitle}</HeadingStyled>
            <ParagraphStyled>{labelDescription}</ParagraphStyled>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormCreditCard
                        availablePaymentMethods={props.availablePaymentMethods}
                    />
                    <FlexStyled alignItems="center" justifyContent="end">
                        <ButtonStyled
                            className={methods.formState.isValid ? 'isvalid' : ''}
                            data-testid={"submitButton"}
                            type="submit"
                            label={labelContinue}
                        />
                    </FlexStyled>
                </form>
            </FormProvider>
        </FormCreditCardValidationStyled>
    );
};

export default FormCreditCardValidation;
