import styled from "styled-components";
import { useTranslation } from "react-i18next";
import logoIfoodPago from './images/logo_ifood_pago.svg'

export const ContainerStyled = styled.div`
    ${props => props.theme.box_secured_by_ifood_pago};
    display: flex;
`;

export const ParagraphStyled = styled.span`
    text-align: center;
    color: ${({ theme }) => theme.colors.gray.t500};
    font-size: ${({ theme }) => theme.fontsSizes.sm.size};
`;

export const ImgStyled = styled.img`
    margin-right: 4px;
    min-width: 80px;
`;

export const BoxSecuredByIfoodPago = (): JSX.Element => {
    const { t } = useTranslation('common');
    const labelDescription = t("box_secured_by_ifood_pago.description");

    return (
        <ContainerStyled>
            <ImgStyled src={logoIfoodPago} alt="Logo do iFood Pago" />
            <ParagraphStyled>{labelDescription}</ParagraphStyled>
        </ContainerStyled>
    );
};

export default BoxSecuredByIfoodPago;
