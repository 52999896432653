import ConfigService, { type ENV } from '../services/Configs/ConfigsService'

export interface IRepository {
  Call: <T>(url: string, config: RequestInit) => Promise<T>
}

export default class Repository implements IRepository {
  private readonly baseUrl: string
  private readonly timeOutMs: number
  private readonly defaultConfig: RequestInit = {
    headers: {
      'content-type': 'application/json'
    }
  }

  constructor (baseUrl: string, timeOut: number, config?: RequestInit) {
    this.baseUrl = baseUrl
    this.timeOutMs = timeOut
    if (config !== undefined) this.defaultConfig = config
  }

  private timeOutController (timeMs: number): AbortController {
    const controller = new AbortController()
    setTimeout(() => { controller.abort() }, timeMs)
    return controller
  }

  /*
   * A generic method to make HTTP requests and return JSON data
   * @return Returns a promise that resolves to a generic type T, If the response status is not OK, reject the promise with the response object
   */
  async Call<T>(url: string, config: RequestInit): Promise<T> {
    config.headers = { ...config.headers, ...this.defaultConfig.headers }
    url = (!url.startsWith('http')) ? `${this.baseUrl}${url}` : url

    const timeOutController = this.timeOutController(this.timeOutMs)
    config.signal = timeOutController.signal

    return await fetch(url, config)
      .then(async (response) => {
        if (!response.ok) {
          return await Promise.reject(response)
        }
        return await response.json()
      })
      .then((data) => data as T)
  }
}

export interface BadRequestResponse {
  message: string
  localized_message: string
  expectedAction?: {
    action: string
    additionalData: Record<string, string>
  }
}

export const createRepositoryWithDefaultConfig = (configsEnv: ENV): IRepository => {
  const configService = new ConfigService(configsEnv)
  const baseUrl = configService.get<string>('api.baseUrl')
  const timeout = configService.get<number>('api.timeout.ms')
  return new Repository(baseUrl, timeout)
}
